.print-body {
    background-color: white;
}

.text-indent {
    text-indent: 4rem;
}

.print-font {
    font-family: 'Cambria', sans-serif;
    color: black;
}

@media print {
    @page {
        size: A4 portrait;
    }

    .print-th .header-img {
        background-color: white;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
    }
    
    .print-th .footer-img {
        background-color: white;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }
}

.print-table {
    background-color: white;
    width: 100%;
}

.print-th {
    background-color: white;
    height: 219px;
}

.print-td {
    padding: 0 6rem;
    background-color: white;
    font-size: 20px;
}

.print-td-custom {
    background-color: white;
}

.print-ul {
    list-style-type: none;
    padding: 0;
}