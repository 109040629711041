@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;200;300;400;500;600;800;900&display=swap);

* {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgb(200, 200, 200);
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(-45deg, #0f207c 75%, #ffb901 25%);
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(150, 0, 0);
  }
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
}

.translate-x-50 {
  transform: translateX(-50%);
}

.translate-y-50 {
  transform: translateY(-50%);
}

.text-truncate {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.custom-popup-class {
  width: 24em !important;
  background-color: #0f207c !important;
  background: linear-gradient(-180deg, rgba(15, 31, 122) 10%, rgba(9, 19, 73) 100%) !important;
  border-bottom: 0.25rem solid #ffb901 !important;
  border-radius: 1rem !important;
  background-size: cover !important;
  color: white !important;
}
.custom-title-class {
  color: white !important;
}
.custom-text-class {
  color: rgb(175, 175, 175) !important;
}
.custom-confirm-btn-class {
  background-color: #ffb901 !important;
  color: black !important;
  padding: 0px 20px !important;
}
.custom-cancel-btn-class {
  background-color: #be2617 !important;
  color: white !important;
  padding: 0px 20px !important;
}
.swal2-progress-bar {
  background-color: #ff0000 !important; /* Warna latar belakang progress bar */
}

.swal2-progress-steps {
  background-color: #00ff00 !important; /* Warna isi progress bar */
}

.mobile-justify-content-center {
    justify-content: center;
}

.child {
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); */
  text-wrap: nowrap;
  display: none;
}

.parent {
  cursor: pointer;
}
.parent:hover .child {
  display: inline-block;
}

.parent:hover .mask {
  opacity: 0%;
}
@media (min-width: 576px) {
    .mobile-justify-content-center {
        justify-content: unset;
    }
}
.w-lg-50 {
  width: auto;
}
@media (min-width: 992px) {
    .w-lg-50 {
        width: 50%;
    }
}

.placeholder-secondary::placeholder {
  color: #aaa !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.accordion-button {
  background-color: #0f207c !important;
}
.accordion-button:hover {
  background-color: #0d1b61 !important;
}
.accordion-button:focus {
  background-color: #0d1b61 !important;
}

.text-warning {
    color: #ffb901 !important;
}
.text-warning-2 {
    color: #b17f00 !important;
}
.text-warning-3 {
    color: #db9900 !important;
}
.text-purple {
    color: #aa00ff !important;
}
.text-primary {
    color: #0f207c !important;
}
.text-primary-2 {
    color: #172eb4 !important;
}
.text-success {
    color: #198754 !important;
}
.text-success-2 {
    color: #00c569 !important;
}
.text-danger {
    color: #dc3545 !important;
}
.text-danger-2 {
    color: #ff0019 !important;
}
.text-danger-3 {
    color: #ab0011 !important;
}
.link-primary-decoration {
  color: #087ab6 !important;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.link-primary-decoration:hover {
  color: #065681 !important;
}
.text-animate {
  animation: text-animate 0.5s infinite alternate ease;
}
@keyframes text-animate {
  0% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(2px);
  }
}
.bg-valid {
  background-color: rgb(25, 135, 84, 0.5) !important;
}
.bg-invalid {
  background-color: rgb(220, 56, 72, 0.5) !important;
}
.bg-invalid-2 {
  background-color: rgb(255, 183, 0, 0.5) !important;
}
.bg-none {
    background-color: #00000000 !important;
}
.bg-silver {
  background-color: rgb(220, 220, 220) !important;
}
.bg-silver-2 {
  background-color: rgb(240, 240, 240) !important;
}
.bg-silver-3 {
    background-color: rgb(255, 255, 255) !important;
}
.bg-dark {
    background-color: #505050 !important;
}
.bg-success {
    background-color: #198754 !important;
}
.bg-success-2 {
    background-color: #177045 !important;
}
.bg-warning {
    background-color: #ffb901 !important;
}
.bg-warning-2 {
    background-color: #ffcf55 !important;
}
.bg-primary {
    background-color: #0f207c !important;
}
.bg-primary-2 {
    background-color: #0b59a1 !important;
}
.bg-primary-3 {
    background-color: #087ab6 !important;
}
.bg-primary-4 {
    background-color: #091349 !important;
}
.invalid-color {
    background: linear-gradient(90deg, rgba(200,0,0,1) 0%, rgba(200,0,0,0) 75%);
}
.valid-color {
    background: linear-gradient(90deg, #198754, rgba(200,0,0,0) 75%);
}
.btn-check .btn-outline-primary {
  border-color: #0b59a1 !important;
  color: #0b59a1 !important;
}
.btn-check .btn-outline-primary:checked {
  border-color: #0b59a1 !important;
  background-color: red !important;
  color: #fff !important;
}
.bg-gradient-primary {
    background-color: #0f207c;
    background-image: linear-gradient(180deg, #0f207c 10%, #091349 100%);
    background-size: cover;
}
.bg-gradient-warning {
    background-color: #ffb901;
    background-image: linear-gradient(180deg, #ffb901 10%, #ab7b00 100%);
    background-size: cover;
}
.bg-gradient-success {
    background-color: #177045;
    background-image: linear-gradient(180deg, #177045 10%, #0f4b2e 100%);
    background-size: cover;
}
.bg-gradient-brown {
    background-color: rgb(150, 70, 0);
    /* background-image: linear-gradient(180deg, brown 10%, brown 100%); */
    background-size: cover;
}
.border-silver {
    border-color: #bbbbbb !important;
}
.hover-border-info:hover {
    border-color: #ffb901 !important;
}
.border-bottom-warning {
    border-bottom: 0.25rem solid #ffb901 !important;
}
.border-bottom-success {
    border-bottom: 0.25rem solid #00c569 !important;
}
.font-logo {
    font-family: 'Playfair Display', serif;
}
.text-sm {
    font-size: 12px !important;
}
.text-xs {
    font-size: 10px !important;
}
.form-control-custom:hover {
    border-color: #087ab6 !important;
}
.form-control-custom:focus {
    border-color: #087ab6 !important;
}
.form-select-custom:hover {
    border-color: #087ab6 !important;
}
.form-select-custom:focus {
    border-color: #087ab6 !important;
}
.dropdown-item:hover, .dropdown-item:focus {
    background-color: rgb(9, 19, 73, 0.5) !important;
}
.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
    background-color: rgb(9, 19, 73, 0.5) !important;
}
.city-background {
    background: linear-gradient(rgb(15, 32, 124, 0.9) 50%, rgb(15, 32, 124, 0.9) 50%), url(https://dev.alfaprima.id/assets/img/city.svg);
    background-position: center;
    background-size: cover;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0,0, 0.3), inset 0 0 5rem rgba(0, 0,0, 0.25);
}
.btn-transparent {
    background-color: #00000000 !important;
}
.btn-transparent:hover {
    background-color: #00000000 !important;
}
.btn-transparent:focus {
    background-color: #00000000 !important;
}
.link-white {
    color: #fff !important;
}
.link-white:hover {
    color: #ddd !important;
}
.link-white:focus {
    color: #ddd !important;
}
.link-black {
    color: #000 !important;
}
.link-black:hover {
    color: #555 !important;
}
.link-black:focus {
    color: #555 !important;
}
.box-image {
  border: 2px solid #6e707e !important;
  border-style: dashed !important;
  color: #6e707e;
  transition: 0.1s ease-in-out;
}
.box-image:hover {
  background-color: rgb(11, 89, 161, 0.25);
  color: #03bbe1;
  cursor: pointer;
}

.box-image-2 {
  background-color: rgba(200, 200, 200, 0.15);
  border: 2px solid #bbbbbb !important;
  border-style: dashed !important;;
  color: #6e707e;
  transition: 0.1s ease-in-out;
}
.box-image-2:hover {
  background-color: rgba(200, 200, 200, 0.25);
  color: #172eb4;
  cursor: pointer;
}

.custom-file-button input[type=file] {
  margin-left: -2px !important;
  box-shadow: inset 0 0.5rem 0.5rem rgba(0, 0,0, 0.2);
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}
.child-item-hover {
  display: none;
}
.head-item-hover:hover .child-item-hover {
  display: block;
}
.btn-primary-2 {
    background-color: #0b59a1 !important;
    color: white !important;
    border: none;
    transition: 0.1s ease-in;
}
.btn-primary-2:hover {
    background-color: #094680 !important;
    color: white !important;
    border: none;
}
.btn-primary-2:focus {
    background-color: #0b59a1 !important;
    color: white !important;
    border: none;
}
.btn-primary-2:active {
    background-color: #094680 !important;
    color: white !important;
    border: none;
}

.btn-primary-3 {
background-color: #172eb4 !important;
color: white !important;
border: none;
transition: 0.1s ease-in;
/* transform: scale(1); */
}
.btn-primary-3:hover {
background-color: #0f207c !important;
color: white !important;
border: none;
/* transform: scale(1.025); */
}
.btn-primary-3:focus {
background-color: #172eb4 !important;
color: white !important;
border: none;
/* transform: scale(1); */
}
.btn-primary-3:active {
background-color: #0f207c !important;
color: white !important;
border: none;
/* transform: scale(1.025); */
}

.btn-primary-4 {
background-color: #0f207c !important;
color: white !important;
border: none;
transition: 0.1s ease-in;
/* transform: scale(1); */
}
.btn-primary-4:hover {
background-color: #162ca8 !important;
color: white !important;
border: none;
/* transform: scale(1.025); */
}
.btn-primary-4:focus {
background-color: #0f207c !important;
color: white !important;
border: none;
/* transform: scale(1); */
}
.btn-primary-4:active {
background-color: #162ca8 !important;
color: white !important;
border: none;
/* transform: scale(1.025); */
}

.btn-warning-2 {
background-color: #ffb901 !important;
color: black !important;
border: none;
transition: 0.1s ease-in;
}
.btn-warning-2:hover {
background-color: #dd9e00 !important;
color: black !important;
border: none;
}
.btn-warning-2:focus {
background-color: #ffb901 !important;
color: black !important;
border: none;
}
.btn-warning-2:active {
background-color: #dd9e00 !important;
color: black !important;
border: none;
}

.btn-success-2 {
background-color: #008069 !important;
color: white !important;
border: none;
transition: 0.1s ease-in;
}
.btn-success-2:hover {
background-color: #00614f !important;
color: white !important;
border: none;
}
.btn-success-2:focus {
background-color: #008069 !important;
color: white !important;
border: none;
}
.btn-success-2:active {
background-color: #00614f !important;
color: white !important;
border: none;
}
.btn-success-3 {
  background-color: #00a385 !important;
  color: white !important;
  border: none;
  transition: 0.1s ease-in;
}
.btn-success-3:hover {
  background-color: #008069 !important;
  color: white !important;
  border: none;
}
.btn-success-3:focus {
  background-color: #00a385 !important;
  color: white !important;
  border: none;
}
.btn-success-3:active {
  background-color: #008069 !important;
  color: white !important;
  border: none;
}
.btn-danger-2 {
  background-color: #be2617 !important;
  color: white !important;
  border: none;
  transition: 0.1s ease-in;
}
.btn-danger-2:hover {
  background-color: #811910 !important;
  color: white !important;
  border: none;
}
.btn-danger-2:focus {
  background-color: #be2617 !important;
  color: white !important;
  border: none;
}
.btn-danger-2:active {
  background-color: #811910 !important;
  color: white !important;
  border: none;
}

.btn-dark {
background-color: #505050 !important;
color: white !important;
border: none;
transition: 0.1s ease-in;
}
.btn-dark:hover {
background-color: #353535 !important;
color: white !important;
border: none;
}
.btn-dark:focus {
background-color: #505050 !important;
color: white !important;
border: none;
}
.btn-dark:active {
background-color: #353535 !important;
color: white !important;
border: none;
}













.loader-awesome {
    width: 60px;
    height: 40px;
    position: relative;
    display: inline-block;
    --base-color: #0f207c; /*use your base color*/
}
  .loader-awesome::before {
    content: '';  
    left: 0;
    top: 0;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #FFF;
    background-image: radial-gradient(circle 8px at 18px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 18px 0px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 0px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 36px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 18px 36px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 30px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 5px 30px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 5px 5px, var(--base-color) 100%, transparent 0);
    background-repeat: no-repeat;
    box-sizing: border-box;
    animation: rotationBack 2s linear infinite;
}
  .loader-awesome::after {
    content: '';  
    left: 35px;
    top: 15px;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #FFF;
    background-image: radial-gradient(circle 5px at 12px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 12px 0px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 0px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 24px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 12px 24px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 20px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 3px 20px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 3px 3px, var(--base-color) 100%, transparent 0);
    background-repeat: no-repeat;
    box-sizing: border-box;
    animation: rotationBack 3s linear infinite reverse;
}
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
}  














  .loader-awesome-2 {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    animation: rotationLW2 1s linear infinite;
  }
  .loader-awesome-2::after,
  .loader-awesome-2::before {
    content: '';  
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 2px solid #00a6ff;
    position: absolute;
    left: 0;
    top: 0;
  }
  .loader-awesome-2::after {
    border-color: #ffb901;
    transform: rotate(45deg);
    /* animation-direction: alternate-reverse; */
  }
  
  /* @keyframes rotationLW2 {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */
  @keyframes rotationLW2 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

      
  














  .loader-awesome-3{
    display: block;
    position: relative;
    height: 12px;
    width: 200px;
    /* border: 1px solid #aaa; */
    background-color: #aaa;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25);
  }
  .loader-awesome-3::after {
    content: '';
    width: 40%;
    height: 100%;
    border-radius: 10px;
    background-color: #00a6ff;
    background-image: linear-gradient(180deg, #00a6ff 10%, #087ab6 100%);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
  