@media print {
    body {
        zoom: 1;
        margin: 0;
        padding: 1cm;
    }
    header, footer {
      display: none;
    }
    .page-break {
      page-break-before: always;
    }
  }